import styles from './Leaderboard.module.scss'

const leaderboard = (props) => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      <div className={styles.close} onClick={props.close}>
        X CLOSE
      </div>
      <h1>Leaderboard</h1>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <tbody>
            {props.scores.map((score,i) => (
              <tr key={`score_${i}`}>
                <td><span className={styles.highScoreNumber}>{i + 1}</span> {score.name}</td>
                <td>{score.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    
  </div>
)

export default leaderboard