import * as Phaser from 'phaser'

let gameObject, thisScene, camera, cursors;
let level = 1;
let player, character, portal, collectibles;
const boxes = []
let boxOrigin, boxOrigin2, boxOrigin3, boxOrigin4;
let boxSpeed = 60;

let flashRedTimer, flashClearTimer, boxTimer;

let totalCoins, totalMedpacks;
let coinsCollected, medpacksCollected, enemyHits;

let firstTouch = true;
let touchControls = [];
let sessionTimer, sessionTimerEnabled;

class Scene extends Phaser.Scene {

  preload() {
    this.load.image("spiritbox-tiles", '/game/tilesets/spiritbox-tiles.png');
    this.load.tilemapTiledJSON("map-1", '/game/tilesets/spiritbox-level-1.json');
    this.load.tilemapTiledJSON("map-2", '/game/tilesets/spiritbox-level-2.json');
    this.load.tilemapTiledJSON("map-3", '/game/tilesets/spiritbox-level-3.json');
    this.load.tilemapTiledJSON("map-4", '/game/tilesets/spiritbox-level-4.json');

    //COLLECTABLES
    // this.load.spritesheet('item-ring', '/game/sprites/item-ring.png',
    //       { frameWidth: 34, frameHeight: 60 });
    this.load.spritesheet('coin', '/game/sprites/coin.png',
          { frameWidth: 20, frameHeight: 20 });
    this.load.spritesheet('med-pack', '/game/sprites/med-pack.png',
          { frameWidth: 16, frameHeight: 18 });
    
    this.load.image('box', '/game/sprites/box.jpg');
    this.load.spritesheet('portal', '/game/sprites/switch.png',
          { frameWidth: 32, frameHeight: 16 });

    //PLAYER
    this.load.atlas("ghost-1", "/game/sprites/ghost-1.png", "/game/sprites/ghost-1.json");
  }

  create() {
    gameObject = this;
    thisScene = this.scene;
    cursors = this.input.keyboard.createCursorKeys();

    const map = this.make.tilemap({ key: `map-${level}`});
    const tileset = map.addTilesetImage("spiritbox-tiles", "spiritbox-tiles");

    sessionTimer = 0
    sessionTimerEnabled = true
    coinsCollected = 0
    medpacksCollected = 0
    enemyHits = 0
    if (level === 1) {
      totalCoins = 6
      totalMedpacks = 1
    } else if (level === 2) {
      totalCoins = 9
      totalMedpacks = 1
    } else if (level === 3) {
      totalCoins = 10
      totalMedpacks = 1
    } else if (level === 4) {
      totalCoins = 14
      totalMedpacks = 2
    }

    const backgroundLayer = map.createLayer("background", tileset, 0, 0);
    const platforms = map.createLayer("platforms", tileset, 0, 0);
    platforms.setCollisionByProperty({ collides: true });

    this.physics.world.bounds.width = map.widthInPixels;
    this.physics.world.bounds.height = map.heightInPixels;

    const spawnPoint = map.findObject("character", obj => obj.name === "spawn-point");
    boxOrigin = map.findObject("enemies", obj => obj.name === "box-origin");
    boxOrigin2 = map.findObject("enemies", obj => obj.name === "box-origin-2");
    boxOrigin3 = map.findObject("enemies", obj => obj.name === "box-origin-3");
    boxOrigin4 = map.findObject("enemies", obj => obj.name === "box-origin-4");

    character = 'ghost-1';
    player = this.physics.add
      .sprite(spawnPoint.x, spawnPoint.y, character, 'ghost-1-1')
    player.body.setSize(28,28)
    player.body.setOffset(0,8)
    
    this.anims.create({
      key: 'float',
      frames: this.anims.generateFrameNames(character, {
        prefix: 'ghost-1-',
        start: 1,
        end: 16
      }),
      frameRate: 10,
      repeat: -1
    });
    this.anims.create({
      key: 'ghost-hit',
      frames: this.anims.generateFrameNames(character, {
        prefix: 'ghost-1-',
        start: 17,
        end: 17
      }),
      frameRate: 10,
      repeat: -1
    });
    player.anims.play('float')
    player.speed = 100;
    player.setBounce(0.0);
    player.body.setGravityY(340);
    player.setCollideWorldBounds(true);

    //PORTAL
    const portalOrigin = map.findObject("portals", obj => obj.name === "portal");
    portal = this.physics.add
      .sprite(portalOrigin.x, portalOrigin.y, "portal");
    this.anims.create({
      key: 'switch-off',
      frames: this.anims.generateFrameNumbers('portal', { start: 0, end: 0 }),
      frameRate: 10,
      repeat: 0
    });
    portal.anims.play('switch-off');
    this.anims.create({
      key: 'switch-on',
      frames: this.anims.generateFrameNumbers('portal', { start: 1, end: 1 }),
      frameRate: 10,
      repeat: 0
    });
    portal.body.setAllowGravity(false);

    //COLLECTIBLES
    const collectibleObjects = map.getObjectLayer('collectibles')['objects'];
    collectibles = this.physics.add.staticGroup();
    collectibleObjects.forEach(object => {
      let obj = collectibles.create(object.x, object.y, object.name);
      obj.name = object.name;

      if (object.name === 'med-pack') {
        this.anims.create({
          key: object.name + '-animate',
          frames: this.anims.generateFrameNumbers(object.name, { start: 0, end: 7 }),
          frameRate: 10,
          repeat: -1
        });
        obj.anims.play(object.name + '-animate');
      } else {
        this.anims.create({
          key: object.name + '-animate',
          frames: this.anims.generateFrameNumbers(object.name, { start: 0, end: 8 }),
          frameRate: 5,
          repeat: -1
        });
        obj.anims.play(object.name + '-animate');
        obj.setScale(0.8)
      }
    });

    boxes.length = 0
    addBox()

    this.physics.add.collider(player, platforms);
    this.physics.add.collider(boxes, platforms, testCollide);
    this.physics.add.overlap(player, collectibles, collectItem, null, this);
    this.physics.add.overlap(player, boxes, hitEnemey, null, this);
    this.physics.add.overlap(player, portal, levelComplete, null, this);

    camera = this.cameras.main;
    camera.startFollow(player);
    camera.setBounds(0, 0, map.widthInPixels, map.heightInPixels);
  }

  update() {
    if (sessionTimerEnabled) {
      sessionTimer += 1
    }
    movePlayer()
    moveBoxes()
  }
}

const movePlayer = () => {
  if (!window.Modernizr.touchevents) {
    if (cursors.left.isDown) {
      player.direction = 'left';
    } else if (cursors.right.isDown) {
      player.direction = 'right';
    } else {
      player.direction = 'idle';
    }

    if (cursors.up.isDown && player.body.blocked.down) {
      player.direction = 'jump';
    }
  }

  if (player.direction === 'left') {
    player.setVelocityX(-player.speed);
    player.flipX = true;
  } else if (player.direction === 'right') {
    player.setVelocityX(player.speed);
    player.flipX = false;
  } else if (player.direction === 'idle') {
    player.setVelocityX(0);
  }

  if (player.direction === 'jump' && player.body.blocked.down) {
    player.setVelocityY(-240);
  }
}

const mobileControl = (pointer) => {
  if (firstTouch) {
    document.dispatchEvent(new CustomEvent('patchPlayAudio'));
    firstTouch = false;
  }

  pointer.preventDefault();
  for (let i = 0; i < touchControls.length; i++) {
    var touchCount = pointer.touches.length;
    
    if (touchCount > 0) {
      // Get the most recent touch in case there are multiple
      var touch = pointer.touches[touchCount - 1];
      
      if (touch.clientX >= touchControls[i].bounds.left && touch.clientX <= touchControls[i].bounds.right && touch.clientY >= touchControls[i].bounds.top && touch.clientY <= touchControls[i].bounds.bottom) {
        player.direction = touchControls[i].direction;
      }
    } else {
      player.direction = 'idle';
    }
  }
}

const moveBoxes = () => {
  // boxes.forEach(box => {
  //   if (box.body) {
      
  //     if (box.body.blocked.right) {
  //       box.setVelocityX(-boxSpeed)
  //     } else if (box.body.blocked.left) {
  //       box.setVelocityX(boxSpeed)
  //     } else {
  //       box.setVelocityX(boxSpeed)
  //     }
  //   }
  // })
}

const testCollide = (box, tile) => {
  // console.log(one, two)
  if (tile.properties.direction === 'right') {
    box.setVelocityX(boxSpeed)
  } else if (tile.properties.direction === 'left') {
    box.setVelocityX(-boxSpeed)
  } else {
    box.setVelocityX(boxSpeed)
  }
}

const addBox = () => {
  let origin, randomDelay;
  if (boxOrigin3 && boxOrigin4) {
    const randomOrigin = Math.floor(Math.random() * 4 + 1)
    randomDelay = Math.random() * (2500 - 1000) + 1000
    if (randomOrigin === 4) {
      origin = boxOrigin4;
    } else if (randomOrigin === 3) {
      origin = boxOrigin3;
    } else if (randomOrigin === 2) {
      origin = boxOrigin2;
    } else {
      origin = boxOrigin
    }
  } else if (boxOrigin2) {
    const randomOrigin = Math.floor(Math.random() * 2 + 1)
    randomDelay = Math.random() * (3000 - 1500) + 1500
    if (randomOrigin === 2) {
      origin = boxOrigin2;
    } else {
      origin = boxOrigin
    }
  } else {
    origin = boxOrigin
    randomDelay = Math.random() * (8000 - 3000) + 3000
  }

  const box = gameObject.physics.add
    .sprite(origin.x, origin.y, 'box')

  box.setBounce(0.0);
  box.body.setGravityY(300);
  box.setCollideWorldBounds(true);
  boxes.push(box);

  boxTimer = setTimeout(() => {
    addBox()
  }, randomDelay)
}

function flashRed() {
  flashRedTimer = setTimeout(() => {
    player.setTint(0xF3A2A2);
    flashClear();
  }, 100);
}
function flashClear() {
  flashClearTimer = setTimeout(() => {
    player.clearTint();
    flashRed();
  }, 100);
}
const hitEnemey = () => {
  if (!player.hitSafe) {
    player.anims.play('ghost-hit')

    enemyHits += 1
    document.dispatchEvent(new CustomEvent('updateScore', {detail: -1}));
    document.dispatchEvent(new CustomEvent('updateHealth', {detail: -1}));
    document.dispatchEvent(new CustomEvent('updateSessionTimer', {detail: sessionTimer}))
    player.hitSafe = true

    setTimeout(() => {
      player.anims.play('float')
    }, 500);

    
    flashRed();
    setTimeout(() => {
      player.hitSafe = false
      clearTimeout(flashRedTimer);
      clearTimeout(flashClearTimer);
      player.clearTint();
    }, 3000);
  }
}

const collectItem = (player, item) => {
  // itemRing.anims.play('item-ring');
  item.disableBody(true, true);

  if (item.name === 'coin') {
    coinsCollected += 1
    document.dispatchEvent(new CustomEvent('updateScore', {detail: 20}));
  } else if (item.name === 'med-pack') {
    medpacksCollected += 1
    document.dispatchEvent(new CustomEvent('updateHealth', {detail: 1}));
  }
}

const levelComplete = () => {
  portal.anims.play('switch-on');
  pauseGame()

  // Level complete bonus
  // const itemBonus = (coinsCollected + medpacksCollected) * 2
  // const allCoinBonus =  coinsCollected === totalCoins ? 50 : 0
  // const allMedpackBonus =  medpacksCollected === totalMedpacks ? 25 : 0
  // const noHitBonus = enemyHits === 0 ? 50 : 0
  // const randomBonus = parseInt(Math.random() * (20 - 1) + 1)
  // const totalBonus = itemBonus + allCoinBonus + allMedpackBonus + noHitBonus + randomBonus
  // document.dispatchEvent(new CustomEvent('updateScore', {detail: totalBonus}));
  // document.dispatchEvent(new CustomEvent('updateSessionTimer', {detail: sessionTimer}))
  document.dispatchEvent(new CustomEvent('levelComplete'));
}

const pauseGame = () => {
  if (boxTimer) {
    clearTimeout(boxTimer)
  }
  gameObject.physics.pause();
  sessionTimerEnabled = false
}

const startNextLevel = () => {
  level += 1

  thisScene.restart();
}

const restart = () => {
  thisScene.restart();
}

const addControls = () => {
  document.addEventListener('startNextLevel', () => {
    // resetPlayerAnims();
    // resetPortalAnims();
    startNextLevel();
  });
  document.addEventListener('playAgain', () => {
    level = 1
    restart();
  });
  document.addEventListener('gameOver', () => {
    // resetPlayerAnims();
    // resetPortalAnims();
    sessionTimerEnabled = false
    pauseGame();
  });
  if (window.Modernizr.touchevents) {
    let controlButtons = document.querySelectorAll('[data-role="touch-control"]');
    controlButtons.forEach((button) => {
      touchControls.push({bounds: button.getBoundingClientRect(), direction: button.dataset.action});
    });

    controlButtons.forEach(btn => {
      btn.addEventListener( 'touchstart', mobileControl, false);
      btn.addEventListener( 'touchmove', mobileControl, false);
      btn.addEventListener( 'touchend', mobileControl, false);
    })
  }
}

export {Scene, addControls}