import styles from './Screens.module.scss';

const instructions = (props) => (
  <div className={styles.wrapper}>
    <div className={styles.content}>

      <div className={styles.instructionStepWrap}>
        <div className={styles.instructionStep} data-active={props.instructionStep === 1}>
          <img src='/images/ghost.gif' />
          {window.Modernizr.touchevents
            ?
            <div>
              <p>
                Tap the arrow buttons to move
              </p>
            </div>
            :
            <div>
              <img src='' />
              <p>
                Use your arrow keys to move
              </p>
            </div>
          }
        </div>

        <div className={styles.instructionStep} data-active={props.instructionStep === 2}>
          <div className={styles.collectibles}>
            <img src='/images/med-pack.gif' />
            <img src='/images/coin.gif' />
          </div>
          <p>
            Collect coins and med-packs to rack up points and boost health
          </p>
        </div>

        <div className={styles.instructionStep} data-active={props.instructionStep === 3}>
          <div>
            <img src='/images/platforms.gif' />
          </div>
          <p>
            Find the switch to complete the level and avoid boxes along the way
          </p>
          <button onClick={props.confirmInstructions} className='btn btn--primary'>START!</button>
        </div>
      </div>
    </div>
  </div>
)

export default instructions;