import { useEffect, useState } from 'react'
import Nav from './containers/Nav/Index'
import Game from './containers/Game/Index'
import Welcome from './components/Screens/Welcome'
import Instructions from './components/Screens/Instructions'
import Leaderboard from './components/Screens/Leaderboard'

function App() {
  const [ui, setUI] = useState('welcome');
  const [audioReady, setAudioReady] = useState(false);
  const [scores, setScores] = useState([])
  const [leaderboardActive, setLeaderboardActive] = useState(false)
  const [instructionStep, setInstructionStep] = useState(1)

  const [sessionToken, setSessionToken] = useState()

  useEffect(() => {
    setupAudio();
    getScores();

    const currentURL = window.location;
    const currentURLParams = new URLSearchParams(currentURL.search);
    const scorekey = currentURLParams.get('scorekey');
    
    if (scorekey && scorekey === "onetakevocalcovers@gmail.com") {
      console.log('SHOUL PURGE')
      fetch('/purge', {
        method: 'GET'
      })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.log(error))
    }
  }, []);

  const setupAudio = () => {
    const patchbayScript = document.createElement('script');
    patchbayScript.type = 'module';
    patchbayScript.onload = function () {
      //console.log('IM script')
    };
    patchbayScript.src = "https://js.patchbay.co/v1/embed.js?key=63080b64e1c7b15cbd265ae7";
    document.head.appendChild(patchbayScript);

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(patchbayScript, firstScriptTag);

    document.addEventListener('audioOptOut', () => {
      confirmWelcome();
    });
    document.addEventListener('audioReady', () => {
      setAudioReady(true);
    });
  }

  const getScores = () => {
    fetch('/scores', {
      method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
      setScores(data)
    })
    .catch(error => console.log(error))
  }

  const confirmWelcome = () => {
    setUI('instructions');
    document.dispatchEvent(new CustomEvent('patchPlayAudio'));

    setTimeout(() => {
      setInstructionStep(2)
    }, 3000)
    setTimeout(() => {
      setInstructionStep(3)
    }, 6000)
  }

  const createSessionToken = () => {
    fetch('/token', {
      method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
      setSessionToken(data.token)
    })
    .catch(error => console.log(error))
  }

  const clearSessionToken = () => {
    setSessionToken()
  }

  const confirmInstructions = () => {
    setUI('game');
    createSessionToken();
    document.dispatchEvent(new CustomEvent('patchPlayAudio'));
  }

  const toggleLeaderBoard = () => {
    if (leaderboardActive) {
      setLeaderboardActive(false)
    } else {
      setLeaderboardActive(true)
    }
  }

  return (
    <div>
      {ui === 'welcome' &&
        <Welcome audioReady={audioReady} confirmWelcome={confirmWelcome} />
      }
      {ui === 'instructions' &&
        <Instructions confirmInstructions={confirmInstructions} instructionStep={instructionStep} />
      }
      {ui === 'game' &&
        <Game
          openLeaderboard={toggleLeaderBoard}
          getScores={getScores}
          sessionToken={sessionToken}
          createSessionToken={createSessionToken}
          clearSessionToken={clearSessionToken}
        />
      }

      <Nav toggleLeaderBoard={toggleLeaderBoard} />
      {leaderboardActive &&
        <Leaderboard scores={scores} close={toggleLeaderBoard} />
      }
      <div class='patchbay-player' data-id="63b8bc3fbf5c295d3c2ffb0b"></div>
    </div>
  );
}

export default App;
