import styles from './Screens.module.scss';

const welcome = (props) => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      
      <div className={styles.welcomeTitle}>Eternal Boo Game</div>

      <p>
        
      </p>

      <div className={styles.enterCta}>
        {props.audioReady
          ? <button onClick={props.confirmWelcome} className='btn btn--primary'>START</button>
          : <div class="patchbay-patch" data-id="63b8bc3fbf5c295d3c2ffb0b">Loading...</div>
        }
      </div>
      
    </div>
  </div>
)

export default welcome;