import { Fragment } from 'react';
import styles from './Header.module.scss'

const header = (props) => (
  <Fragment>
    <div className={styles.title}>Spritibox</div>

    <div onClick={props.togglePanel} className={styles.navTrigger} data-open={props.menuOpen}>
      <div className={styles.iconCenter}></div>
      <div className={styles.iconAnimate}></div>
    </div>
  </Fragment>
)

export default header;