import styles from './MenuPanel.module.scss';
import IconSpotify from '../Icons/Spotify'
import IconApple from '../Icons/Apple'
import IconTidal from '../Icons/Tidal'
import IconYouTube from '../Icons/YouTube'
import IconFacebook from '../Icons/Facebook'
import IconInstagram from '../Icons/Instagram'
import IconTwitter from '../Icons/Twitter'

const menuPanel = (props) => (
  <div className={styles.wrapper} data-active={props.menuOpen}>
    <div className={styles.content}>
      <div className={styles.nav}>
        <ul>
          <li className={styles.label} onClick={props.toggleLeaderBoard}>LEADERBOARD</li>
          <li className={styles.label}><a href='https://spiritbox.com/pages/tour' target='_blank'>TOUR</a></li>
          <li className={styles.label}><a href='https://spiritbox.com/' target='_blank'>MERCH</a></li>
        </ul>

        <div className={styles.socials}>
          <span className={styles.label}>FOLLOW</span>
          <ul>
            <li className='subtitle'><a href='https://open.spotify.com/artist/4MzJMcHQBl9SIYSjwWn8QW?si=Bgw-1cA-TSe97ps5HGiy2w&nd=1' target='_blank' rel='noreferrer'><IconSpotify /></a></li>
            <li className='subtitle'><a href='https://music.apple.com/us/artist/spiritbox/1293047384' target='_blank' rel='noreferrer'><IconApple /></a></li>
            <li className='subtitle'><a href='https://tidal.com/browse/artist/9163057' target='_blank' rel='noreferrer'><IconTidal /></a></li>
            <li className='subtitle'><a href='https://www.youtube.com/channel/UCmWxquutalJ1ZjdmWLdlhSg' target='_blank' rel='noreferrer'><IconYouTube /></a></li>
            <li className='subtitle'><a href='https://www.instagram.com/spiritboxmusic/' target='_blank' rel='noreferrer'><IconInstagram /></a></li>
            <li className='subtitle'><a href='https://www.facebook.com/Spiritboxofficial' target='_blank' rel='noreferrer'><IconFacebook /></a></li>
            <li className='subtitle'><a href='https://twitter.com/spiritboxband' target='_blank' rel='noreferrer'><IconTwitter /></a></li>
          </ul>
        </div>
      </div>
      <div className={styles.album}>
        <div className={styles.albumArt}>
          <img src='/images/album-art.jpg' alt='album art' />
          <div className='flex-center flex-wrap'>
            <a href='' target='_blank' rel='noreferrer'>Stream & Buy</a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default menuPanel;