import { useState } from 'react';
import React from 'react';
import Header from "../../components/Nav/Header"
import MenuPanel from '../../components/Nav/MenuPanel'

export default function Index(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  const togglePanel = () => {
    if (menuOpen) {
      setMenuOpen(false)
    } else {
      setMenuOpen(true);
    }
  }

  return (
    <React.Fragment>
      <MenuPanel menuOpen={menuOpen} toggleLeaderBoard={props.toggleLeaderBoard} />
      <Header togglePanel={togglePanel} menuOpen={menuOpen} />
    </React.Fragment>
  )
}