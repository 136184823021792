import styles from './Controls.module.scss'
import IconArrowLeft from '../Icons/ArrowLeft'
import IconArrowRight from '../Icons/ArrowRight'

const controls = (props) => (
  <div className={styles.wrapper}>
    <div className={styles.direction}>
      <div data-role="touch-control" data-action="left"></div>
      <div data-role="touch-control" data-action="right"></div>
    </div>
    <div className={styles.jump} data-role="touch-control" data-action="jump">JUMP!</div>
  </div>
)

export default controls