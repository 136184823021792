import * as Phaser from 'phaser'
import { useEffect, useState, useRef, Fragment } from 'react'
import styles from '../../components/Game/Game.module.scss'
import {Scene, addControls} from './Game'
import Status from '../../components/Game/Status'
import Message from '../../components/Game/Message'
import MobileControls from '../../components/Game/MobileControls'
import ScoreForm from './ScoreForm'

let config, game;
let gameWidth, gameHeight;

export default function Index(props) {
  const wrapperRef = useRef(null)

  const [level, setLevel] = useState(1)
  const levelRef = useRef(1);

  const [score, setScore] = useState(0)
  const scoreRef = useRef(0);
  
  const [health, setHealth] = useState(3)
  const healthRef = useRef(3)

  const [sessionTimer, setSessionTimer] = useState(0)
  const sessionTimerRef = useState(0)

  const [gameState, setGameState] = useState('playing')

  const [canAddScore, setCanAddScore] = useState(true)
  const [scoreFormActive, setScoreFormActive] = useState(false)

  const coinsCollected = useRef(0)
  const medpacksCollected = useRef(0)
  const enemyHits = useRef(0)

  const currentSessionRef = useRef(null)

  useEffect(() => {
    if (props.sessionToken) {
      currentSessionRef.current = props.sessionToken
    }
  }, [props.sessionToken])

  useEffect(() => {
    initGame()

    document.addEventListener('updateScore', (e) => {
      if (scoreRef.current + e.detail >= 0) {
        scoreRef.current += e.detail
        setScore(score => score += e.detail)

        if (e.detail === 20) {
          coinsCollected.current += 1
        } else if (e.detail === -1) {
          enemyHits.current += 1
        }
      }
    })

    document.addEventListener('updateHealth', (e) => {
      if (e.detail > 0 && healthRef.current !== 3) {
        healthRef.current += e.detail
        setHealth(health => health += e.detail)
      } else if (e.detail < 0 && healthRef.current > 0) {
        healthRef.current += e.detail
        setHealth(health => health += e.detail)
      }

      if (healthRef.current === 0) {
        setGameState('game-over')
        document.dispatchEvent(new CustomEvent('gameOver'));
        updateSession('game-over')
      }

      if (e.detail > 0) {
        medpacksCollected.current += 1
      }
    })

    document.addEventListener('updateSessionTimer', (e) => {
      sessionTimerRef.current += e.detail
      setSessionTimer(e.detail)
    })

    document.addEventListener('levelComplete', (e) => {
      if (levelRef.current === 4) {
        setGameState('game-won')
      } else {
        setLevel(level => level += 1)
        setGameState('level-complete')
      }
      levelRef.current += 1
      updateSession('level-complete')
    })
  }, [])

  const updateSession = (gameEvent) => {
    const body = {a: coinsCollected.current, b: medpacksCollected.current, c: levelRef.current - 1, d: enemyHits.current, e: gameEvent}
    fetch(`/session`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${currentSessionRef.current}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      setScore(data.score)
      scoreRef.current = data.score
    })
    .catch(error => {
      console.log('error: ', error)
    })
  }

  const deleteSession = () => {
    fetch(`/session`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${currentSessionRef.current}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      // console.log(data)
    })
    .catch(error => {
      console.log('error: ', error)
    })
  }
  
  const initGame = () => {
    if (window.innerWidth < 600) {
      gameWidth = 320;
      gameHeight = 400;
    } else {
      gameWidth = 320;
      gameHeight = 340;
    }

    config = {
      type: Phaser.AUTO,
      width: 800,
      height: 600,
      pixelArt: true,
      antiAlias: true,
      scale: {
        parent: 'game-wrapper',
        mode: Phaser.Scale.WIDTH_CONTROLS_HEIGHT,
        width: gameWidth,
        height: gameHeight
      },
      autoCenter: Phaser.Scale.NONE,
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 10 },
          debug: false
        }
      },
      scene: Scene
    }

    game = new Phaser.Game(config)

    addControls()
  }

  const nextLevel = () => {
    coinsCollected.current = 0
    medpacksCollected.current = 0
    enemyHits.current = 0
    setGameState('playing')
    document.dispatchEvent(new CustomEvent('startNextLevel'))
  }

  const playAgain = () => {
    coinsCollected.current = 0
    medpacksCollected.current = 0
    enemyHits.current = 0

    setCanAddScore(true)
    setGameState('playing')
    setScore(0)
    scoreRef.current = 0
    setHealth(3)
    healthRef.current = 3
    setLevel(1)
    levelRef.current = 1
    setSessionTimer(0)
    sessionTimerRef.current = 0
    document.dispatchEvent(new CustomEvent('playAgain'))

    deleteSession()
    props.createSessionToken()
  }

  const scoreAdded = () => {
    setScoreFormActive(false)
    setCanAddScore(false)
    props.getScores()
    props.clearSessionToken()
  }

  const toggleScoreForm = () => {
    if (scoreFormActive) {
      setScoreFormActive(false)
    } else {
      setScoreFormActive(true)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div ref={wrapperRef} id='game-wrapper' className={styles.gameWrapper}>

        <Status score={score} health={health} />
      </div>
      
      {window.Modernizr.touchevents &&
        <MobileControls />
      }
      
      {gameState !== 'playing' && !scoreFormActive &&
        <Message
          score={score}
          canAddScore={canAddScore}
          gameState={gameState}
          nextLevel={nextLevel}
          playAgain={playAgain}
          openScoreForm={toggleScoreForm}
          openLeaderboard={props.openLeaderboard}
        />
      }
      {scoreFormActive &&
        <ScoreForm
          sessionTimer={sessionTimer}
          sessionToken={props.sessionToken}
          score={score}
          scoreAdded={scoreAdded}
          closeScoreForm={toggleScoreForm}
        />
      }
    </div>
  )
}