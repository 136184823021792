import { Fragment } from 'react'
import Sharing from '../../containers/Social/Sharing'
import styles from './Message.module.scss'

const message = (props) => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      <div className={styles.header}>
        {props.gameState === 'level-complete' &&
          <span>LEVEL COMPLETE!</span>
        }
        {props.gameState === 'game-over' && props.canAddScore &&
          <Fragment>
            <Sharing />
            <span>GAME OVER!</span>
          </Fragment>
        }
        {props.gameState === 'game-won' && props.canAddScore &&
          <span>YOU WON!</span>
        }
        {(props.gameState === 'game-over' || props.gameState === 'game-won') && !props.canAddScore &&
          <span>SCORE ADDED!</span>
        }
      </div>

      <div className={styles.score}>
        <div className={styles.scoreLabel}>
          YOUR SCORE:
        </div>
        <div className={styles.scoreNumber}>
          {props.score}
        </div>
      </div>

      <div className={styles.cta}>
        {props.gameState === 'level-complete' &&
          <button onClick={props.nextLevel} className='btn btn--primary'>NEXT LEVEL</button>
        }
        {(props.gameState === 'game-won' || props.gameState === 'game-over') && props.canAddScore &&
          <button onClick={props.openScoreForm} className='btn btn--primary'>ADD SCORE</button>
        }
        {(props.gameState === 'game-won' || props.gameState === 'game-over') && !props.canAddScore &&
          <button onClick={props.openLeaderboard} className='btn btn--primary'>LEADERBOARD</button>
        }
        {(props.gameState === 'game-won' || props.gameState === 'game-over') &&
          <button onClick={props.playAgain} className='btn btn--primary'>PLAY AGAIN</button>
        }
      </div>
    </div>
  </div>
)

export default message