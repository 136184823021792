import styles from './Sharing.module.scss';
import IconFacebook from '../../components/Icons/Facebook'
import IconTwitter from '../../components/Icons/Twitter'

function Sharing(props) {
  const fbShare = (e) => {
		var url = e.target.dataset.url;
		document.dispatchEvent(new CustomEvent('fbShare', {detail: {url: url} }))
	}

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.sharing} ${props.sharingActive ? styles.sharingActive : ''}`}>
        <div className={styles.sharingContent}>
          <div onClick={props.hideSharing} className={styles.closeSharing}>x close</div>
          
          <button onClick={fbShare} className={styles.btnFacebook} data-url='https://traumazine.megantheestallion.com/'><IconFacebook /> share</button>
          <a href="https://twitter.com/intent/tweet?text=Enter the Traumazine experience&url=https://traumazine.megantheestallion.com/&hashtags=traumazine&via=theestallion" className={styles.btnTwitter}><IconTwitter /> share</a>
        </div>
      </div>
    </div>
  )
}

export default Sharing;