import {useState} from 'react'
import styles from '../../components/Game/Message.module.scss'

export default function ScoreForm(props) {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [error, setError] = useState()

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!name || name === '' || !email || email === '') {
      setError('Please fill all fields')
    } else {
      const body = {name, email}
      fetch(`/scores`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${props.sessionToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      .then(response => response.json())
      .then(data => {
        props.scoreAdded()
      })
      .catch(error => {
        console.log('error: ', error)
      })
    }
  }

  const handleChange = (e) => {
    if (e.target.name === 'name') {
      setName(e.target.value)
    } else if (e.target.name === 'email') {
      setEmail(e.target.value)
    }

    setError()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.close} onClick={props.closeScoreForm}>
          X CLOSE
        </div>

        <div>ADD YOUR SCORE</div>
        <form onSubmit={handleSubmit}>
          <div className="input-row">
            <input
              type='text'
              name='name'
              placeholder='Your name...'
              onChange={handleChange}
            />
          </div>
          <div className="input-row">
            <input
              type='email'
              name='email'
              placeholder='Your email...'
              onChange={handleChange}
            />
          </div>
          <span>{error}</span>
          <div className="input-row">
            <button type='submit' className='btn btn--primary btn--block'>ADD SCORE</button>
          </div>
        </form>
      </div>
    </div>
  )
}